import React, { useState } from 'react';
import { Helmet } from 'react-helmet';
import axios from 'axios';
import moment from 'moment-timezone';

const Contact = () => {
    // Set timezone to your local_time
    const timezone = 'America/Regina';
    const now = moment().tz(timezone);
    const currentHour = now.hour();

    // Determine the message based on the current time
    const current_status = currentHour >= 9 && currentHour < 21
        ? `awake`
        : `sleeping`;

    const [formData, setFormData] = useState({ email: '', message: '' });
    const [errors, setErrors] = useState({ email: '', message: '' });

    const validate = () => {
        const newErrors = { email: '', message: '' };
        let isValid = true;

        if (!formData.email || !/\S+@\S+\.\S+/.test(formData.email)) {
            newErrors.email = 'Please enter a valid email address';
            isValid = false;
        }
        if (!formData.message || formData.message.length < 10) {
            newErrors.message = 'Message must be at least 10 characters long';
            isValid = false;
        }

        setErrors(newErrors);
        return isValid;
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        if (validate()) {
            axios.post('/api/contact.php', formData)
                .then(response => {
                    alert(response.data.message);
                    setFormData({ email: '', message: '' });
                })
                .catch(error => {
                    console.error('Error sending message:', error);
                    alert('There was an error sending your message. Please try again later.');
                });
        }
    };

    const handleChange = (event) => {
        const { name, value } = event.target;
        setFormData({ ...formData, [name]: value });
    };

    const structuredData = {
        "@context": "https://schema.org",
        "@type": "WebPage",
        "name": "Contact - JomaDev",
        "description": "Have a question or want to connect? Drop a message below or reach out via Discord, Telegram, Twitter, or email!",
        "url": "https://joma.dev/contact",
        "image": "https://joma.dev/logo.png"
    };

    return (
        <div className="mt-36 w-full" style={{ opacity: 1, transform: "none" }}>
            <Helmet>
                <title>{structuredData["name"]}</title>
                <meta name="description" content={structuredData["description"]} />
                <meta name="keywords" content="Joma, CryptoJoma, JomaDev, Portfolio, Web3, Smart Contracts, Blockchain" />
                <link rel="canonical" href={structuredData["url"]} />
                <meta property="og:title" content={structuredData["name"]} />
                <meta property="og:description" content={structuredData["description"]} />
                <meta property="og:url" content={structuredData["url"]} />
                <meta property="og:type" content="website" />
                <meta property="og:image" content={structuredData["image"]} />
                <meta name="twitter:image" content={structuredData["image"]} />
                <meta name="twitter:card" content="summary" />
                <meta name="twitter:site" content={structuredData["url"]} />
                <meta name="twitter:title" content={structuredData["name"]} />
                <meta name="twitter:description" content={structuredData["description"]} />
                <script type="application/ld+json">
                    {JSON.stringify(structuredData)}
                </script>
            </Helmet>
            <h1 className="text-black dark:text-white font-bold text-3xl mb-3 mt-8">
                Let's <span className="transition-all duration-1000 text-violet-600">Chat</span> <span className="pl-1" role="img" aria-label="wave">💬</span>
            </h1>
            <p className="text-gray-800 dark:text-gray-200 mb-6">
                Have a question or want to connect? Drop a message below or reach out via Discord, Telegram, Twitter, or email!
            </p>
            <p className="text-black/50 dark:text-white/50 text-sm mb-10">
                It's currently <span className="font-semibold text-black/60 dark:text-white/60"> {now.format('h:mm A')}</span> for me, so I’m likely <span className="font-semibold text-black/60 dark:text-white/60">{current_status}</span> and will respond as soon as I can!
            </p>
            <div className="grid grid-cols-1 md:grid-cols-3 md:gap-4">
                <div className="md:col-span-2 h-auto min-h-[21.5rem] row-span-3 bg-opacity-50 bg-white dark:bg-white/5 rounded-md p-4 border border-zinc-800/50">
                    <div style={{ opacity: 1 }}>
                        <form onSubmit={handleSubmit}>
                            <h1 className="font-bold text-sm dark:text-slate-500 mb-1">EMAIL</h1>
                            <input
                                type="email"
                                name="email"
                                className="w-full p-2 mb-4 rounded-md bg-slate-300/50 dark:bg-slate-200/5 text-sm placeholder:text-gray-600 dark:placeholder:text-slate-200/20 border border-transparent outline-none focus:border-slate-700/50 transition-colors duration-300"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder="Your Email"
                                required
                            />
                            {errors.email && <p className="error text-red-500">{errors.email}</p>}
                            <h1 className="font-bold text-sm dark:text-slate-500 mb-1">MESSAGE</h1>
                            <textarea
                                name="message"
                                value={formData.message}
                                className="w-full min-h-[9rem] p-2 h-36 mb-4 rounded-md bg-slate-300/50 dark:bg-slate-200/5 text-sm placeholder:text-gray-600 dark:placeholder:text-slate-200/20 border border-transparent outline-none focus:border-slate-700/50 transition-colors duration-300"
                                onChange={handleChange}
                                placeholder="Howdy partner! I'd like a custom project made in python!"
                                required
                            ></textarea>
                            {errors.message && <p className="error text-red-500">{errors.message}</p>}
                            <div className="w-full flex flex-row justify-between items-center">
                                <p className="text-gray-900 dark:text-gray-300 text-sm"></p>
                                <button type="submit" className="border border-gray-800 hover:bg-gray-200 dark:border-indigo-600/80 dark:bg-indigo-600/70 dark:hover:bg-indigo-500/70 flex flex-row items-center justify-center rounded-full px-5 py-2 text-sm font-medium transition-colors duration-75">
                                    <span className="mt-[2px]">Send</span>
                                    <svg stroke="currentColor" fill="currentColor" strokeWidth="0" viewBox="0 0 24 24" className="ml-2" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M3 13.0001H9V11.0001H3V1.8457C3 1.56956 3.22386 1.3457 3.5 1.3457C3.58425 1.3457 3.66714 1.36699 3.74096 1.4076L22.2034 11.562C22.4454 11.695 22.5337 11.9991 22.4006 12.241C22.3549 12.3241 22.2865 12.3925 22.2034 12.4382L3.74096 22.5925C3.499 22.7256 3.19497 22.6374 3.06189 22.3954C3.02129 22.3216 3 22.2387 3 22.1544V13.0001Z"></path>
                                    </svg>
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="row-start-1 md:row-auto">
                    <a target="_blank" rel="noreferrer noopener" className="hover:border-[#5865F2]/50 shadow-white shadow-none hover:shadow-lg mb-4 row-start-3 flex flex-row items-center bg-opacity-50 bg-white dark:bg-white/5 border border-transparent dark:border-transparent hover:border-slate-500 dark:hover:border-slate-500 rounded-md px-4 py-3 text-sm font-medium transition-colors duration-300" href="mailto:coffee@joma.dev">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8.75V6a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2v2.75l-10 5.5-10-5.5zM3 12l9 5.25L21 12" />
                        </svg>
                        <span className="pl-3">coffee@joma.dev</span>
                    </a>
                    <a target="_blank" rel="noreferrer noopener" className="hover:border-[#5865F2]/50 shadow-white shadow-none hover:shadow-lg mb-4 row-start-2 flex flex-row items-center bg-opacity-50 bg-white dark:bg-white/5 border border-transparent dark:border-transparent hover:border-slate-500 dark:hover:border-slate-500 rounded-md px-4 py-3 text-sm font-medium transition-colors duration-300" href="https://twitter.com/CryptoJoma">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M17 1l-4 4-4-4-4 4 4 4 4-4 4 4 4-4-4-4zM7 11l-4 4 4 4 4-4-4-4zm10 0l4 4-4 4-4-4 4-4z" />
                        </svg>
                        <span className="pl-3">Follow me on Twitter</span>
                    </a>
                    <a target="_blank" rel="noreferrer noopener" className="hover:border-[#5865F2]/50 shadow-white shadow-none hover:shadow-lg flex flex-row items-center bg-opacity-50 bg-white dark:bg-white/5 border border-transparent dark:border-transparent hover:border-slate-500 dark:hover:border-slate-500 rounded-md px-4 py-3 text-sm font-medium transition-colors duration-300" href="https://t.me/CryptoJoma">
                        <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" viewBox="0 0 24 24" fill="none" stroke="currentColor">
                            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 8l3 3 4-4 5 5 5-5 1 1v4h-3l-1 1-1-1h-2v-2l-1-1-4 4-3-3z" />
                        </svg>
                        <span className="pl-3">Join me on Telegram</span>
                    </a>
                </div>
            </div>
        </div>
    );
};

export default Contact;
